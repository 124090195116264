<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-region class="mr-2" v-model:value="region" />
        <filter-provinsi class="mr-2 mt-2" v-model:value="provinsi" v-model:region="region" />
        <filter-area class="mt-2" v-model:value="area" v-model:area="area" v-model:region="region" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-kabupaten-kota
          class="mr-2 mt-2"
          v-model:value="kabupaten"
          v-model:region="region"
          v-model:provinsi="provinsi"
          v-model:area="area"
        />
        <FilterKecamatan
          class="mt-2 mr-2"
          v-model:value="kecamatan"
          v-model:area="area"
          v-model:region="region"
          v-model:kabupaten="kabupaten" />
        <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mr-2 mt-2"
        />
        <a-button class="mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
      <ASpace>
        <ATooltip
          v-has-access="['wilayah.store']"
          title="Tambahkan"
          placement="top">
          <a-button
            type="primary"
            @click="showModal">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </a-button>
        </ATooltip>
        <ATooltip
          title="Download Excel"
          placement="top">
          <DownloadExcel
            url="/api/wilayah"
            :params="queryParams()"
            namefile="Master-Data-Wilayah"
            @errors="errorMessage"/>
        </ATooltip>
      </ASpace>
      </div>
    </div>
    <div class="table-responsive text-nowrap mt-2">
      <md-table
        row-key="id"
        :columns="columns"
        :data-source="data"
        size="small"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #formatDate="{text}">
          <span>{{ text ? moment(text).format('DD-MM-yyyy') : '' }}</span>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Lihat Wilayah">
              <a-button
                class="button"
                size="small"
                @click="lihat(record)"
                v-has-access="['wilayah.show']"
              >
                <i class="fe fe-eye" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Edit Wilayah">
              <a-button
                class="button"
                size="small"
                @click="edit(record)"
                v-has-access="['wilayah.update']"
              >
                <i class="fe fe-edit" />
              </a-button>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
    <m-modal
      v-if="visible"
      v-model:visible="visible"
      :model="model"
      @handleOk="handleOk"
      :destroy-on-close="true"
    ></m-modal>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted, computed, watch } from 'vue'
import MModal from './modal'
import acolumns from './columns'
import qs from 'qs'
import moment from 'moment'
import apiClient from '@/services/axios'
import FilterRegion from '@/components/filter/FilterRegional'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterProvinsi from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKecamatan from '@/components/filter/FilterKecamatan'
import DownloadExcel from '@/components/Molecules/DownloadExcel'

const initial = () => ({
  id: null,
  parent_id: null,
  name: null,
  provinsi: null,
  area_id: null,
  area: null,
  region: null,
  jumlah_kec: null,
  level: null,
  poscode: null,
  record: {},
})

export default defineComponent({
  components: {
    MModal,
    FilterRegion,
    FilterKabupatenKota,
    FilterProvinsi,
    FilterArea,
    FilterKecamatan,
    DownloadExcel,
  },
  emits: ['handleOk'],
  setup() {
    const state = reactive({
      data: [],
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      meta: {
        totalCount: 0,
        perPage: 10,
        currentPage: 1,
      },
      page: 1,
      isFetching: false,
      isDownloading: false,
      search: null,
      q: null,
      kabupaten: [],
      provinsi: [],
      area: [],
      region: [],
      kecamatan: [],
      poscode: null,
    })

    const visible = ref(false)
    const formState = reactive({ ...initial() })

    const handleChange = (pag, filters, sorter) => {
      meta.value.currentPage = pag.current
      meta.value.perPage = pag.pageSize
    }

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const startRow = ref(1)

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const model = reactive({ readOnly: false })

    const showModal = () => {
      model.record = reactive({ ...initial() })
      model.readOnly = false
      model.level = null
      model.poscode = null
      visible.value = true
    }

    const handleOk = form => {
      visible.value = false
      fetchData()
    }

    const errorMessage = ref()

    const columns = ref([])
    columns.value = acolumns
    
    const queryParams = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: state.region,
        kecamatan: state.kecamatan,
        provinsi: state.provinsi,
        area: state.area,
        kabupaten: state.kabupaten,
        q: state.q,
      }
      
      return Object.assign({}, _params)
    }

    const fetchData = () => {
      state.isFetching = true

      apiClient
        .get('/api/wilayah', {
          params: queryParams(),
        })
        .then(response => {
          const { items, _meta } = response.data
          state.data = items
          state.meta = _meta || { pageCount: 0, totalCount: 0 }
          state.pageCount = state.meta.pageCount
          state.totalCount = state.meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    const lihat = record => {
      visible.value = true
      model.record = record
      // model.record.level = null
      model.poscode = null
      model.readOnly = true
    }

    const edit = record => {
      model.record = record
      model.readOnly = false
      model.level = null
      model.poscode = null
      visible.value = true
    }

    const search = () => {
      fetchData()
    }

    return {
      edit,
      lihat,
      perPage,
      pageCount,
      totalCount,
      meta,
      page,
      startRow,
      visible,
      showModal,
      handleOk,
      columns,
      handleChange,
      handleTableChange,
      model,
      ...toRefs(formState),
      ...toRefs(state),
      search,
      errorMessage,
      moment,
      queryParams,
    }
  },
})
</script>
<style scoped>
.card-body {
  margin-left: 20px;
}
.button {
  margin: 0 3px;
}
</style>
